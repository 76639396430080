import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

export default function HomeRightSide2() {
  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate(); // For navigation


  const handleProfileClick = (user_id) => {
    navigate(`/profiles/${user_id}/`);
  };

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        const response = await fetch('https://backproject.meanc.net/meanc/top_players/');
        if (response.ok) {
          const data = await response.json();
          setTopUsers(data);
        } else {
          console.error('Failed to fetch top players:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching top players:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopUsers();
  }, []);

  return (
    <div className="widget widget-jobs">
      <div className="sd-title">
        <h3>{t('topTalent')}</h3>
        <i className="la la-ellipsis-v"></i>
      </div>
      {loading ? (
        <div className="process-comm">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      ) : (
        <div className="jobs-list">
          {topUsers.map(user => (
            <div key={user.id} 
                className="job-info"
                style={{cursor:'pointer'}} 
                onClick={() => handleProfileClick(user.id)}
            >
              <div className="job-details">
                <img src={user.photo || `https://robohash.org/${user.id}`} 
                    alt="Avatar" 
                    className="avatar"/>
                <h3 style={{ paddingLeft:'5px'}}>{user.username}</h3>
                <p><FontAwesomeIcon icon={faUsers} /> {t('followers')}: {user.followers_count}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
