import React, { useState, useEffect, useMemo } from "react";
import TalentVideoDetail from "./TalentVideoDetail";
import SearchData from "../../Services/SearchData";

export default function TalentVideos() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch("https://backproject.meanc.net/meanc/top_players/")
      .then((response) => response.json())
      .then((da) => {
        setData(da);
        setFilteredData(da); // Initialize filteredData with all data initially here
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error.toString());
        setIsLoading(false);
      });
  }, []);

  const debouncedSearchTerm = useDebounce(searchTerm, 500); // Debouncing search term

  useEffect(() => {
    if (!debouncedSearchTerm.trim()) {
      setFilteredData(data);
    } else {
      const lowercasedSearchTerm = debouncedSearchTerm.toLowerCase();
      const filtered = data.filter(item =>
        (item.username && item.username.toLowerCase().includes(lowercasedSearchTerm)) ||
        (item.bio && item.bio.toLowerCase().includes(lowercasedSearchTerm)) ||
        (item.country && item.country.toLowerCase().includes(lowercasedSearchTerm)) // Adjust the fields as necessary
      );
      setFilteredData(filtered);
    }
  }, [debouncedSearchTerm, data]);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <div className="process-comm">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      );
    }
    if (error) return <p>{error}</p>;
    if (filteredData.length === 0) return <p>No videos found.</p>;
    return <TalentVideoDetail data={filteredData} />;
  }, [isLoading, error, filteredData]);

  return (
    <section className="companies-info">
      <div className="container">
        <SearchData searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <div className="companies-list">
          <div className="row">
            {content}
          </div>
        </div>
      </div>
    </section>
  );
}

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
