import React, { createContext, useEffect, useState, useContext } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);

  const fetchData = async (url, setData) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        //credentials: 'include' // Include credentials in the request
      });
      if (response.ok) {
        const data = await response.json();
        setData(data);
      } else {
        throw new Error('Authentication credentials were not provided.');
      }
    } catch (error) {
      console.error(`Error fetching data from ${url}: ${error}`);
    }
  };

  useEffect(() => {
    // Check if data is already available in cache
    if (categories.length === 0) {
      fetchData("https://backproject.meanc.net/meanc/category/", setCategories);
    }
  }, []); // Fetch categories on initial render

  return (
    <DataContext.Provider
      value={{
        categories,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  return useContext(DataContext);
};
