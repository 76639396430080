import React, { useEffect, useState } from 'react';
import { Table, Spinner, Alert, Container, Button, Pagination } from 'react-bootstrap';
import VideoModal from '../Components/Others/Videomodal';

const VideoManagement = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const token = localStorage.getItem('userId');

  useEffect(() => {
    fetch('https://backproject.meanc.net/meanc/videos-display/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`, // Include token for authentication
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setVideos(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [token]);

  const handleUpdateDisplay = (id, currentDisplay, processedVideoUrl) => {
    fetch(`https://backproject.meanc.net/meanc/videos-display/${id}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`, // Include token for authentication
      },
      body: JSON.stringify({
        id: id,  // Include the ID
        processed_video_url: processedVideoUrl,  // Include the processed video URL
        display: !currentDisplay,  // Toggle the display status
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update display status');
        }
        return response.json();
      })
      .then(data => {
        setVideos(prevVideos =>
          prevVideos.map(video =>
            video.id === id ? { ...video, display: data.display } : video
          )
        );
        setShowModal(false); // Close the modal after updating
      })
      .catch(error => {
        console.error('Error updating display status:', error);
      });
  };

  const handleOpenModal = (video) => {
    setSelectedVideo(video);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVideo(null);
  };

  // Pagination logic
  const indexOfLastVideo = currentPage * itemsPerPage;
  const indexOfFirstVideo = indexOfLastVideo - itemsPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert variant="danger">Error: {error.message}</Alert>
      </Container>
    );
  }

  return (
    <Container style={{ marginTop: '90px' }}>
      <h2 className="my-4">Video Management ({videos.length})</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Processed Video URL</th>
            <th>Display Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentVideos.map(video => (
            <tr key={video.id}>
              <td>{video.id}</td>
              <td><a href={video.processed_video_url} target="_blank" rel="noopener noreferrer">View Video</a></td>
              <td style={{ backgroundColor: video.display ? 'green' : 'red', color: 'white', textAlign: 'center' }}>
                {video.display ? 'True' : 'False'}
              </td>
              <td>
                <Button variant="info" onClick={() => handleOpenModal(video)}>Preview</Button>
                {' '}
                <Button
                  variant="warning"
                  onClick={() => handleUpdateDisplay(video.id, video.display, video.processed_video_url)}
                >
                  Toggle
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <Pagination>
        {Array.from({ length: Math.ceil(videos.length / itemsPerPage) }, (_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => paginate(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* Reuse VideoModal component */}
      {selectedVideo && (
        <VideoModal
          isOpen={showModal}
          onClose={handleCloseModal}
          videoSrc={selectedVideo.processed_video_url}
        />
      )}
    </Container>
  );
};

export default VideoManagement;
