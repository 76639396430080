// api.js
import axios from 'axios';

const BASE_URL = 'https://backproject.meanc.net'; // Your API base URL
//const BASE_URL = 'http://127.0.0.1:8000'

// Function to make a GET requests
export async function get(endpoint) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw new Error(`GET request failed: ${error.message}`);
  }
}

// Function to make a POST request

export async function post(endpoint, data, onUploadProgress) {
  const formData = new FormData();

  // Append JSON data
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  try {
    const response = await axios({
      method: 'post',
      url: `${BASE_URL}${endpoint}`,
      data: formData,
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        onUploadProgress(percentCompleted); // Callback to update progress
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}


// Function to make a PUT request
export async function put(endpoint, data) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw new Error(`PUT request failed: ${error.message}`);
  }
}

// Function to make a DELETE request
export async function del(endpoint) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw new Error(`DELETE request failed: ${error.message}`);
  }
}

// Export additional utility functions if required

// Function to make a PATCH request
export async function patch(endpoint, data) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw new Error(`PATCH request failed: ${error.message}`);
  }
}

// For post FECTH API
// export async function post(endpoint, data) {
//   try {
//     const formData = new FormData();

//     // Append JSON data
//     Object.keys(data).forEach((key) => {
//       formData.append(key, data[key]);
//     });
//     console.log('POST URL:', `${BASE_URL}${endpoint}`);
//     console.log('POST Data Inside the API.js:', formData);
//     const response = await fetch(`${BASE_URL}${endpoint}`, {
//       method: 'POST',
//       headers: {
//         // No need to set Content-Type; it will be set automatically by FormData
//         //'Content-Type': 'multipart/form-data',
//         //'Content-Type': 'application/json',
//       },
//       body: formData,
//     });

//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }

//     return await response.json();
//   } catch (error) {
//     throw new Error(`POST request failed: ${error.message}`);
//   }
// }