import React, { useContext, useEffect, useState } from "react";
import ApiContext from "../globalStore/ApiContext";
import Header2 from "../PageLayout/Header2";
import Footer from "../PageLayout/Footer";
import ProfileEditModal from "../Modal/ProfileEditModal";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import PlayerProfile from "./PlayerProfile";
import RecruiterProfile from "./RecruiterProfile";
import SimpleUserProfile from "./SimpleUserProfile";
import VideoModal from "../Others/Videomodal";

export default function HomeUserProfileDetail() {
    const token = localStorage.getItem("userId");
    const { apiData } = useContext(ApiContext);
    const [extraData, setExtraData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        height: 'N/A',
        weight: 'N/A',
        hometown: 'N/A',
        play_position: 'N/A',
        team_name: 'N/A',
        school: 'N/A',
        company: 'N/A',
        contact_number: 'N/A',
        hobbies: 'N/A',
        interests: 'N/A',
        occupation: 'N/A'
    });
    const [videos, setVideos] = useState([]); // State to store videos
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false); // State for video modal
    const [selectedVideo, setSelectedVideo] = useState(null); // State for selected video

    const location = useLocation();
    const user = location.state?.user;

    useEffect(() => {
        if (!user) return;

        const userId = user.id;
        let url = '';
        if (user.user_type === 'player') {
            url = `https://backproject.meanc.net/meanc/players/get-by-user/${userId}/`;
        } else if (user.user_type === 'recruiter') {
            url = `https://backproject.meanc.net/meanc/recruiters/get-by-user/${userId}/`;
        } else if (user.user_type === 'simple') {
            url = `https://backproject.meanc.net/meanc/simple-profiles/get-by-user/${userId}/`;
        }

        fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setExtraData(data || {});
            setFormData({
                height: data?.height || 'N/A',
                weight: data?.weight || 'N/A',
                hometown: data?.hometown || 'N/A',
                play_position: data?.play_position || 'N/A',
                school: data?.school || 'N/A',
                team_name: data?.team_name || 'N/A',
                company: data?.company || 'N/A',
                contact_number: data?.contact_number || 'N/A',
                hobbies: data?.hobbies || 'N/A',
                interests: data?.interests || 'N/A',
                occupation: data?.occupation || 'N/A'
            });
        })
        .catch(error => console.log("Error fetching data:", error));
    }, [token, user]);

    // Fetch videos if the user type is player
    useEffect(() => {
        if (user?.user_type === 'player') {
            fetch(`https://backproject.meanc.net/meanc/video/by-user/${user.id}/`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setVideos(data || []); // Store videos in state
            })
            .catch(error => console.log("Error fetching videos:", error));
        }
    }, [user, token]);

    const handleEditClick = () => {
        setShowModal(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSaveClick = () => {
        if (!user) return;

        const userId = user.id;
        let url = '';

        if (user.user_type === 'player') {
            url = `https://backproject.meanc.net/meanc/players/update-by-user/${userId}/`;
        } else if (user.user_type === 'recruiter') {
            url = `https://backproject.meanc.net/meanc/recruiters/update-by-user/${userId}/`;
        } else {
            url = `https://backproject.meanc.net/meanc/simple-profiles/update-by-user/${userId}/`;
        }

        fetch(url, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
            body: JSON.stringify(formData)
        })
        .then(response => response.json())
        .then(data => {
            setFormData({
                height: data.height || 'N/A',
                weight: data.weight || 'N/A',
                hometown: data.hometown || 'N/A',
                play_position: data.play_position || 'N/A',
                school: data.school || 'N/A',
                team_name: data.team_name || 'N/A',
                company: data.company || 'N/A',
                contact_number: data.contact_number || 'N/A',
                hobbies: data.hobbies || 'N/A',
                interests: data.interests || 'N/A',
                occupation: data.occupation || 'N/A'
            });
            setIsEditing(false);
            setShowModal(false);
        })
        .catch(error => console.log("Error saving data:", error));
    };

    const openVideoModal = (videoSrc) => {
        setSelectedVideo(videoSrc);
        setIsVideoModalOpen(true);
    };

    const closeVideoModal = () => {
        setIsVideoModalOpen(false);
        setSelectedVideo(null);
    };

    const renderUserProfile = () => {
        if (!user) return null;

        if (user.user_type === 'player') {
            return (
                <PlayerProfile 
                    extraData={extraData} 
                    isEditing={isEditing} 
                    formData={formData} 
                    user = {user}
                    handleInputChange={handleInputChange} 
                    handleSaveClick={handleSaveClick}
                    handleEditClick={handleEditClick}
                />
            );
        } else if (user.user_type === 'recruiter') {
            return (
                <RecruiterProfile 
                    extraData={extraData} 
                    isEditing={isEditing} 
                    formData={formData} 
                    user = {user}
                    handleInputChange={handleInputChange} 
                    handleSaveClick={handleSaveClick}
                    handleEditClick={handleEditClick}
                />
            );
        } else {
            return (
                <SimpleUserProfile 
                    extraData={extraData} 
                    isEditing={isEditing} 
                    formData={formData}
                    user = {user} 
                    handleInputChange={handleInputChange} 
                    handleSaveClick={handleSaveClick}
                    handleEditClick={handleEditClick}
                />
            );
        }
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className="wrapper">
            <>
                {!apiData || apiData.length === 0 ? (
                    <div className="lds-facebook">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                ) : (
                    <>
                        <Header2 />
                        <section
                            className="cover-sec"
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '150px',
                                backgroundImage: 'url("https://www.transparenttextures.com/patterns/clean-gray-paper.png")',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        >
                            <div className="usr-pic" style={{ position: 'absolute', bottom: '-15px', left: '70px', borderRadius: '50%' }}>
                                {user?.photo ? (
                                    <img src={`${user.photo}`} alt={`User ${user.id}`}
                                        style={{ cursor: 'pointer', background: '#63b0e3', width: '100px', height: '100px' }} />
                                ) : (
                                    <img src={`https://robohash.org/${user?.id}`}
                                        style={{ cursor: 'pointer', background: '#63b0e3', width: '100px', height: '100px' }} />
                                )}
                            
                            </div>
                        </section>
                        <main>
                            <div className="main-section">
                                <div className="container" style={{ marginTop: '-35px' }}>
                                    <div className="main-section-data">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="main-left-sidebar">
                                                    <div className="user_profile">
                                                        <div className="user_pro_status">
                                                            <ul className="flw-status">
                                                                <li>
                                                                    <span>Following</span>
                                                                    <b>{user?.following_count || 0}</b>
                                                                </li>
                                                                <li>
                                                                    <span>Followers</span>
                                                                    <b>{user?.follower_count || 0}</b>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <ul className="social_links">
                                                            <li>Username<a href="#" title="">{user?.username}</a></li>
                                                            <li>Email <a href="#" title="">{user?.email}</a></li>
                                                            <li>Account Type<a href="#" title="">{user?.user_type}</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {renderUserProfile()}
                                            <div className="col-lg-3">
                                                <div className="right-sidebar">
                                                    <div className="widget widget-portfolio">
                                                        <div className="wd-heady">
                                                            <h3>Videos</h3>
                                                            <img src="images/photo-icon.png" alt="" />
                                                        </div>
                                                        <div className="pf-gallery">
                                                            <ul>
                                                                {videos.length > 0 ? (
                                                                    videos.map((video, index) => (
                                                                        <li
                                                                            key={index}
                                                                            style={{
                                                                                backgroundColor: 'orange',
                                                                                padding: '20px',
                                                                                marginRight: '10px',
                                                                                boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={() => openVideoModal(video.processed_video_url)}
                                                                        >
                                                                            <span>
                                                                                <FontAwesomeIcon
                                                                                    icon={faPlayCircle}
                                                                                    size="2x"
                                                                                />
                                                                            </span>
                                                                        </li>
                                                                    ))
                                                                ) : (
                                                                    <li>No videos available</li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>
                )}
            </>
            <Footer />

            <ProfileEditModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                user={user}
                formData={formData}
                setFormData={setFormData}
                userType={user?.user_type}
                handleSaveClick={handleSaveClick}
            />

            {/* Video Modal */}
            <VideoModal
                isOpen={isVideoModalOpen}
                onClose={closeVideoModal}
                videoSrc={selectedVideo}
            />
        </div>
    );
}
