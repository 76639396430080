import React, { useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { Form, Button, Col, Row, Alert, Card, Container } from 'react-bootstrap';
import Header4 from "../PageLayout/header4";
import Footer from "../PageLayout/Footer";
import ApiContext from "../globalStore/ApiContext";

export default function ForgotPassword() {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [codeSent, setCodeSent] = useState(false);
    const [resetCode, setResetCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate(); // Initialize useNavigate
    const user = useContext(ApiContext).apiData[0];

    const handlePasswordResetRequest = (e) => {
        e.preventDefault();
        fetch('https://backproject.meanc.net/meanc/request-password-reset/', {
            method: 'POST',
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ email })
        })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                setMessage(data.message);
                setCodeSent(true);
                setError("");
            } else {
                setError(data.error);
                setMessage("");
            }
        })
        .catch(error => {
            setError(t('request_error'));
            setMessage("");
        });
    };

    const handlePasswordResetConfirm = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError(t('password_mismatch'));
            return;
        }
        fetch('https://backproject.meanc.net/meanc/confirm-password-reset/', {
            method: 'POST',
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ reset_code: resetCode, new_password: newPassword })
        })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                setMessage(data.message);
                setError("");
                navigate('/', { state: { email } });
            } else {
                setError(data.error);
                setMessage("");
            }
        })
        .catch(error => {
            setError(t('reset_error'));
            setMessage("");
        });
    };

    if(user){
        return <Navigate to="/" />
    }

    return (
        <>
        <Header4 />
        <div className="confirmation-container">
            <div className="image-container">
                <img src="images/passwordReset.jpg" alt="Reset Password" style={{ width: '50%', height: 'auto', borderRadius: '10px' }} />
            </div>
            <div className="form-container">
            <h5 style={{ marginTop: '20px', textAlign: 'center', color: '#007bff' }}>{t('reset_password_instructions')}</h5>
            {!codeSent ? (
                <Form onSubmit={handlePasswordResetRequest}>
                    <Form.Group controlId="email" className="mb-3">
                        <Form.Label>{t('enter_email')}</Form.Label>
                        <Form.Control type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={{ padding: '10px', borderRadius: '5px' }} />
                    </Form.Group>
                    {error && <Alert variant="danger" style={{ marginTop: '10px' }}>{error}</Alert>}
                    {message && <Alert variant="success" style={{ marginTop: '10px' }}>{message}</Alert>}
                    <Button type="submit" className="w-100" style={{ marginTop: '20px', padding: '10px', borderRadius: '5px', backgroundColor: '#007bff', border: 'none' }}>{t('send_reset_link')}</Button>
                </Form>
            ) : (
                <Form onSubmit={handlePasswordResetConfirm}>
                    <Form.Group controlId="resetCode" className="mb-3">
                        <Form.Label>{t('enter_reset_code')}</Form.Label>
                        <Form.Control type="text"
                            value={resetCode}
                            onChange={(e) => setResetCode(e.target.value)}
                            required
                            style={{ padding: '10px', borderRadius: '5px' }} />
                    </Form.Group>
                    <Form.Group controlId="newPassword" className="mb-3">
                        <Form.Label>{t('enter_new_password')}</Form.Label>
                        <Form.Control type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            style={{ padding: '10px', borderRadius: '5px' }} />
                    </Form.Group>
                    <Form.Group controlId="confirmPassword" className="mb-3">
                        <Form.Label>{t('confirm_new_password')}</Form.Label>
                        <Form.Control type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            style={{ padding: '10px', borderRadius: '5px' }} />
                    </Form.Group>
                    {error && <Alert variant="danger" style={{ marginTop: '10px' }}>{error}</Alert>}
                    {message && <Alert variant="success" style={{ marginTop: '10px' }}>{message}</Alert>}
                    <Button type="submit" className="w-100" style={{ marginTop: '20px', padding: '10px', borderRadius: '5px', backgroundColor: '#007bff', border: 'none' }}>{t('reset_password')}</Button>
                </Form>
            )}
        </div>
        </div>
        <Footer />
        </>
    );
}
