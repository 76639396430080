import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FollowButton from "../../Utils/FollowButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faMessage } from '@fortawesome/free-solid-svg-icons';
import { maskEmail } from "../../Utils/maskEmail";

export default function ProfilePostDetail() {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    fetch("https://backproject.meanc.net/meanc/recruiters/all-recruiters/", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        setData(data);
        setIsLoading(false);
        console.log("This is data from recruiter:", data);
      })
      .catch(error => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  const handleProfileClick = (user_id) => {
    console.log("This is the user id in recruiter selected", user_id);
    navigate(`/profiles/${user_id}/`);
  };

  const handleMessageClick = (user_id) => {
    navigate(`/messenger?user=${user_id}`);
  };

  if (error) {
    return <div>An error occurred: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div className="process-comm">
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      {data.map(item => (
        <div key={item.id} className="col-lg-3 col-md-4 col-sm-6 col-12">
          <div className="company_profile_info">
            <div className="company-up-info">
              {
                  item.photo ? (
                    <img 
                      src={`${item.photo}`} 
                      alt="Avatar" 
                      style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor: 'pointer' }} 
                      onClick={() => handleProfileClick(item.user_id)} // Add click handler
                    />
                  ) : (
                    <img 
                      src={`https://robohash.org/${item.user_id}`} 
                      alt="Avatar" 
                      style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor: 'pointer' }} 
                      onClick={() => handleProfileClick(item.user_id)} // Add click handler
                    />
                  )}
              <h4>{item.company? item.company:'MEANC SPORTS'}</h4>
              <h4>{maskEmail(item.email)}</h4> 
              <ul>
                <li>
                  <FollowButton userId={item.user_id} />
                </li>
              </ul>
            </div>
            <a 
                onClick={() => handleProfileClick(item.user_id)}
                style={{ margin: "10px 34px", float: 'left', color: '#2980b9', cursor: 'pointer', boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding: '10px', borderRadius: '50%' }}>
                <FontAwesomeIcon icon={faUserAlt} />
              </a>
              <a 
                onClick={() => handleMessageClick(item.user_id)}
                style={{ margin: "10px 34px", float: 'right', color: '#2980b9',cursor: 'pointer', boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding: '10px', borderRadius: '50%' }}>
                <FontAwesomeIcon icon={faMessage} />
              </a>
          </div>
        </div>
      ))}
    </>
  );
}
