import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ApiContext from "./globalStore/ApiContext";
import { DataContext } from '../Context/DataProvider';
import moment from "moment";
import { FaTimes } from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner';
import TimeComponent from '../Utils/TimeComponent';
import LikeComponent from '../Utils/LikeComponent';
import PostComment from '../Utils/PostComment';
import DeleteButton from '../Utils/DeleteButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faFootballBall, faBaseballBall, faBasketballBall, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export default function Feedback() {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { apiData } = useContext(ApiContext);
  const currentUserId = apiData && apiData.length > 0 ? apiData[0].id : null;
  const [selectedFeedbackId, setSelectedFeedbackId] = useState(null);
  const [reply, setReply] = useState('');
  const [replies, setReplies] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const replyFormRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { categories } = useContext(DataContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showAllReplies, setShowAllReplies] = useState({});

  const addNewPost = (newPost) => {
    setData((prevData) => [newPost, ...prevData]);
  };

  const iconMap = {
    'Soccer': faFutbol,
    'NFL Football': faFootballBall,
    'Baseball': faBaseballBall,
    'Basketball': faBasketballBall,
    'default': faPlusCircle  // Fallback icon
  };
  
  const handleDelete = async (postId) => {
    if (!currentUserId) {
      console.error('No user data available.');
      return;
    }

    try {
      const response = await fetch(`https://backproject.meanc.net/meanc/posts/${postId}`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error('Failed to delete the post.');
      setData(prevData => prevData.filter(item => item.id !== postId));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
 
  const fetchData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await fetch('https://backproject.meanc.net/meanc/posts/');
      const json = await result.json();
      const dataWithLikes = json.map(item => ({
        ...item,
        likeCount: item.liked ? item.liked.length : 0
      }));
      setData(dataWithLikes);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (replyFormRef.current && !replyFormRef.current.contains(event.target)) {
        setSelectedFeedbackId(null);
      }
    };

    if (selectedFeedbackId !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedFeedbackId]);

  const handleReplySubmit = async (e, feedbackId, parentId = null) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://backproject.meanc.net/meanc/replies/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: reply,
          post: feedbackId,
          user: apiData[0].id,
          parent: parentId,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const json = await response.json();
      setReplies((prevReplies) => [...prevReplies, json]);
      setReply('');
      setSelectedFeedbackId(null);
    } catch (error) {
      console.error('Error:', error);
      alert("Failed to post reply. Please try again later.");
    }
    setIsSubmitting(false);
  };

  const handleReplyChange = (e) => {
    setReply(e.target.value);
  };

  useEffect(() => {
    const fetchReplies = async () => {
      try {
        const response = await fetch('https://backproject.meanc.net/meanc/replies/');
        const json = await response.json();
        setReplies(json);
      } catch (error) {
        console.log('Error:', error);
      }
    };
    fetchReplies();
  }, []);

  const toggleReplies = (postId) => {
    setShowAllReplies((prevShowAllReplies) => ({
      ...prevShowAllReplies,
      [postId]: !prevShowAllReplies[postId]
    }));
  };

  const renderReplies = (replies, parentId) => {
    return replies
      .filter((replyItem) => replyItem.parent === parentId)
      .map((replyItem) => (
        <div key={replyItem.id} className="reply-item">
          <div className="reply-content">
            <div className="reply-line"></div>
            <img
              src={replyItem.user_photo ? replyItem.user_photo : `https://robohash.org/${replyItem.user}`}
              alt=""
              style={{ cursor: 'pointer', width: '30px', height: '35px', background:'#63b0e3', }}
            />
            <div className="reply-text">
              <div className="reply-info">
                <span className="user-id">{replyItem.username}</span>
              </div>
              <p>{replyItem.content}</p>
              <button className='replyButton' >
                <TimeComponent time={replyItem.created} />
              </button>
              <button className='replyButton' onClick={() => setSelectedFeedbackId(replyItem.id)}>
                {t('reply')}
              </button>
              <button className='replyButton'>
                {t('like')} ({replyItem.likeCount || 0})
              </button>
              {selectedFeedbackId === replyItem.id && (
                <form ref={replyFormRef} onSubmit={(e) => handleReplySubmit(e, replyItem.post, replyItem.id)}>
                  <input
                    type="text"
                    placeholder={t('replyToFeedback')}
                    maxLength={200}
                    value={reply}
                    onChange={handleReplyChange}
                  />
                  <button type="submit">{t('reply')}</button>
                </form>
              )}
              {renderReplies(replies, replyItem.id)}
            </div>
          </div>
        </div>
      ));
  };

  return (
    <>
      <section className="companies-info">
        <div className="container">
          <div className="company-title" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <h3 style={{ marginRight: '20px', marginBottom:'9px' }}>{t('letsTalkSports')}</h3>
              {categories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => setSelectedCategory(category.name)}
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    margin: '0 10px',
                    marginBottom: '5px',
                    padding: '5px 10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    background: category.name === selectedCategory ? 'white' : 'transparent'
                  }}>
                  <FontAwesomeIcon icon={iconMap[category.name] || iconMap['default']} style={{ marginRight: '5px' }} />
                  {category.name}
                </button>
              ))}
              <button onClick={() => setSelectedCategory(null)} 
                    style={{  
                      display: 'inline-flex',
                      alignItems: 'center',
                      margin: '0 10px',
                      marginBottom: '5px',
                      padding: '5px 10px',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                       }}>
                {t('showAllCategories')}
              </button>
          </div>
          <div className="companies-list">
            <div className="row">
              <div className="comment-section">
                {isError && <div>{t('somethingWentWrong')}</div>}
                <PostComment addNewPost={addNewPost} />
                {isLoading ? (
                  <div>{t('loading')} <Spinner animation="border" /> </div>
                ) : (
                  <div className="comment-sec" style={{ overflow: 'auto', maxHeight: '450px' }}>
                    {data.filter(item => selectedCategory == null || item.category_name === selectedCategory).map((item) => (
                      <div key={item.id} className="comment-item" style={{ position: 'relative'}}>
                        {currentUserId === item.user && (
                          <DeleteButton onDelete={() => handleDelete(item.id)} />
                        )}
                        <div className="comment-item">
                          <img
                            src={item.user_photo ? item.user_photo : `https://robohash.org/${item.user}`}
                            id="avatar"
                            alt=""
                            style={{ cursor: 'pointer', width: '30px',height:'35px', margin:'10px', borderRadius:'50%',background:'green', }}
                          />
                          <div className="comment-text" style={{marginRight:'15px'}}>
                            <h3>{item.username}</h3>
                            <p>/{item.category_name ? item.category_name : "None"}</p>
                            <p className='content-text'>{item.content}</p>
                            <div className="reply-section">
                              {selectedFeedbackId === item.id ? (
                                <form ref={replyFormRef} onSubmit={(e) => handleReplySubmit(e, item.id)}>
                                  <input
                                    type="text"
                                    placeholder={t('replyToFeedback')}
                                    maxLength={200}
                                    value={reply}
                                    onChange={handleReplyChange}
                                  />
                                  <button type="submit">{t('reply')}</button>
                                </form>
                              ) : (
                                <div className='replayButtonSection'>
                                  <button className='replyButton' >
                                    <TimeComponent time={item.created} />
                                  </button>
                                  <button
                                    className='replyButton'
                                    onClick={() => setSelectedFeedbackId(item.id)}
                                  >
                                    <i className="fas fa-reply"></i> {t('reply')}
                                  </button>
                                  <LikeComponent postId={item.id} initialLikeCount={item.likeCount} />
                                </div>
                              )}
                              <div className="replies">
                                {replies
                                  .filter((replyItem) => replyItem.post === item.id)
                                  .slice(0, showAllReplies[item.id] ? replies.length : 3)
                                  .map((replyItem) => (
                                    <div key={replyItem.id} className="reply-item">
                                      <div className="reply-content">
                                        <div className="reply-line"></div>
                                        <img
                                          src={replyItem.user_photo ? replyItem.user_photo : `https://robohash.org/${replyItem.user}`}
                                          alt=""
                                          style={{ cursor: 'pointer', width: '30px',height:'35px', background:'#63b0e3', }}
                                        />
                                        <div className="reply-text">
                                          <div className="reply-info">
                                            <span className="user-id">{replyItem.username}</span>
                                          </div>
                                          <p>{replyItem.content}</p>
                                          <button className='replyButton' >
                                            <TimeComponent time={replyItem.created} />
                                          </button>
                                          <button className='replyButton' onClick={() => setSelectedFeedbackId(replyItem.id)}>
                                            {t('reply')}
                                          </button>
                                          <button className='replyButton'>
                                            {t('like')} ({replyItem.likeCount || 0})
                                          </button>
                                          {selectedFeedbackId === replyItem.id && (
                                            <form ref={replyFormRef} onSubmit={(e) => handleReplySubmit(e, replyItem.post, replyItem.id)}>
                                              <input
                                                type="text"
                                                placeholder={t('replyToFeedback')}
                                                maxLength={200}
                                                value={reply}
                                                onChange={handleReplyChange}
                                              />
                                              <button type="submit">{t('reply')}</button>
                                            </form>
                                          )}
                                          {renderReplies(replies, replyItem.id)}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                {replies.filter((replyItem) => replyItem.post === item.id).length > 3 && (
                                  <button className='buttonMore' onClick={() => toggleReplies(item.id)}>
                                    {showAllReplies[item.id] ? t('readLess') : t('readMore')}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
