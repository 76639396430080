import React, { useState, useEffect, useContext } from "react";
import ApiContext from "../Components/globalStore/ApiContext";

const FollowButton = ({ userId }) => {
  const { apiData } = useContext(ApiContext);
  const [isFollowing, setIsFollowing] = useState(() => {
    // Load follow state from local storage
    const savedFollowing = localStorage.getItem('following');
    const followingState = savedFollowing ? JSON.parse(savedFollowing) : {};
    return followingState[userId] || false;
  });
  console.log("this is the user selected ID", userId)
  useEffect(() => {
    const checkFollowStatus = async () => {
      try {
        const response = await fetch('https://backproject.meanc.net/meanc/follows/');
        if (response.ok) {
          const data = await response.json();
          const followStatus = {};

          data.forEach(follow => {
            if (follow.follower.id === apiData[0].id) {
              followStatus[follow.followed.id] = true;
            }
          });

          // Save to local storage and state
          localStorage.setItem('following', JSON.stringify(followStatus));
          setIsFollowing(followStatus[userId] || false);
        } else {
          console.error('Failed to fetch followers:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching followers:', error);
      }
    };

    checkFollowStatus();
  }, [apiData, userId]);

  const handleFollow = async () => {
    try {
      const response = await fetch('https://backproject.meanc.net/meanc/follows/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify({
          follower_id: apiData[0].id, // Use follower_id as per your backend
          followed_id: userId // Use followed_id as per your backend
        }),
      });

      if (response.ok) {
        setIsFollowing(true);
        const updatedFollowing = JSON.parse(localStorage.getItem('following') || '{}');
        updatedFollowing[userId] = true;
        localStorage.setItem('following', JSON.stringify(updatedFollowing));
      } else {
        console.error('Error following user:', response.status);
      }
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  return (
    <button
      style={{
        cursor: 'pointer',
        backgroundColor: isFollowing ? '#2980b9' : '#fff', // Change color when followed
        color: isFollowing ? '#fff' : '#000', // Change text color when followed
        padding: '8px 12px',
        border: 'none',
        borderRadius: '5px',
        transition: 'background-color 0.3s, color 0.3s',
      }}
      onClick={handleFollow}
      disabled={isFollowing}
    >
      {isFollowing ? 'Followed' : 'Follow me'}
    </button>
  );
};

export default FollowButton;
