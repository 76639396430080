import React, { useContext, useState, useEffect } from 'react';
import ApiContext from '../globalStore/ApiContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

export default function LikeComButton({ postId }) {
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [disliked, setDisliked] = useState(false);
  const [dislikeCount, setDislikeCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { apiData } = useContext(ApiContext);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    async function fetchLikesAndDislikes() {
      try {
        // Fetch like count and user like status
        const likeResponse = await fetch(`https://backproject.meanc.net/meanc/like/count_like_by_videos/?video=${postId}&user=${apiData[0].id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          signal: signal
        });

        // Fetch dislike count and user dislike status
        const dislikeResponse = await fetch(`https://backproject.meanc.net/meanc/dislike/count_dislike_by_videos/?video=${postId}&user=${apiData[0].id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          signal: signal
        });

        if (likeResponse.ok) {
          const likeData = await likeResponse.json();
          setLikeCount(likeData.like_count);
          setLiked(likeData.user_liked);
        } else {
          console.log('Error fetching like count:', likeResponse.status);
        }

        if (dislikeResponse.ok) {
          const dislikeData = await dislikeResponse.json();
          setDislikeCount(dislikeData.dislike_count);
          setDisliked(dislikeData.user_disliked);
        } else {
          console.log('Error fetching dislike count:', dislikeResponse.status);
        }
      } catch (error) {
        console.error('Error fetching like/dislike counts:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchLikesAndDislikes();

    return () => {
      abortController.abort();
    };
  }, [postId, apiData]);

  const handleLike = async (event) => {
    event.preventDefault();
    if (!liked) {
      setLiked(true);
      setLikeCount(likeCount + 1);
      try {
        const response = await fetch('https://backproject.meanc.net/meanc/like/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            users: [apiData[0].id],
            videos: [postId],
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to like post ${postId}`);
        }
      } catch (error) {
        console.error('Error liking post:', error);
        setLiked(false);
        setLikeCount(likeCount - 1);
      }
    }
  };

  const handleDislike = async (event) => {
    event.preventDefault();
    if (!disliked) {
      setDisliked(true);
      setDislikeCount(dislikeCount + 1);
      try {
        const response = await fetch('https://backproject.meanc.net/meanc/dislike/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            users: [apiData[0].id],
            videos: [postId],
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to dislike post ${postId}`);
        }
      } catch (error) {
        console.error('Error disliking post:', error);
        setDisliked(false);
        setDislikeCount(dislikeCount - 1);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div>
          <a href="#" style={{ color: 'white', padding: '20px' }}>loading...</a>
        </div>
      ) : (
    
          <ul>
            <li>
              <p style={{ 
                      margin: "10px 20px", 
                      float: 'left', 
                      color: 'black',
                      backgroundColor: 'white', 
                      borderRadius: '8px', 
                      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', 
                      padding: '5px 5px',
                      marginLeft:'5px'}}>
                <span onClick={handleLike} style={{ cursor: 'pointer', color: liked ? 'navy' : 'black' }}>
                  <FontAwesomeIcon icon={faThumbsUp} />
                  <span> ({likeCount})</span>
                </span>
              </p>
            </li>
            <li>
              <p style={{ 
                        margin: "10px -5px", 
                        float: 'left', 
                        color: 'black',
                        backgroundColor: 'white', 
                        borderRadius: '8px', 
                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)', 
                        padding: '5px 5px',
                         }}>
                <span onClick={handleDislike} style={{ cursor: 'pointer', color: disliked ? 'navy' : 'black' }}>
                  <FontAwesomeIcon icon={faThumbsDown} />
                  <span> ({dislikeCount})</span>
                </span>
              </p>
            </li>
          </ul>
      )}
    </>
  );
}
