import React, { useEffect, useState } from 'react';
import { Table, Spinner, Alert, Container, Form, Button } from 'react-bootstrap';
import Header4 from '../Components/PageLayout/header4';
import Footer from '../Components/PageLayout/Footer';
import VideoManagement from './VideoManagement';
import UserCountReport from './UserCountReport';

const ReferralReport = () => {
  const [referralData, setReferralData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState('');

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);

    fetch('https://backproject.meanc.net/meanc/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            setLoading(false);
            setLoginError('Login failed: ' + (err.message || 'Invalid credentials'));
            throw new Error('Login failed');
          });
        }
        return response.json();
      })
      .then(data => {
        if (!data.token) {
          throw new Error('No token returned');
        }
        console.log('Token:', data.token);
        localStorage.setItem('userId', data.token); // Store the token
        setIsAuthenticated(true);
        setLoginError('');
        setLoading(false);
      })
      .catch(error => {
        setIsAuthenticated(false);
        setLoginError(error.message);
        console.error('Login error:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      const token = localStorage.getItem('userId'); 
      fetch('https://backproject.meanc.net/meanc/referral/referrals-count-all/',{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`, // Include the token in the Authorization header
        },
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setReferralData(data);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }
  }, [isAuthenticated]);

  if (loading) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (!isAuthenticated) {
    return (
      <Container style={{ marginTop: '140px', backgroundColor:'white', padding:'20px',width: '80%', maxWidth: '500px' }}>
        <h2 className="my-4">Login</h2>
        <Form onSubmit={handleLogin}>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control 
              type="email" 
              placeholder="Enter Your Email" 
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)} 
              required />
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control 
              type="password" 
              placeholder="Enter password" 
              name="password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required />
          </Form.Group>

          {loginError && <Alert variant="danger">{loginError}</Alert>}

          <Button variant="primary" style={{ marginTop: '20px' }} type="submit">
            Login
          </Button>
        </Form>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert variant="danger">Error: {error.message}</Alert>
      </Container>
    );
  }

  return (
    <>
      <Header4 />
      <VideoManagement />
      <UserCountReport />
      <Container style={{ marginTop: '20px' }}>
        <h2 className="my-4">Referral Report</h2>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Username</th>
                <th>Email</th>
                <th>Referral Count</th>
                <th>Referrals</th>
              </tr>
            </thead>
            <tbody>
              {referralData.map(user => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.referral_count}</td>
                  <td>
                    <ul>
                      {user.referrals.map(referral => (
                        <li key={referral.referral_id}>
                          {referral.referred_user_username} ({referral.referred_user_email})
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default ReferralReport;
