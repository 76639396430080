import React, {useContext,useEffect,useState, useRef} from "react";
import moment from "moment";
import ApiContext from "../globalStore/ApiContext";
import VideoContext from "../globalStore/VideoContext";
import LikeComButton from "./LikeComButton";
import CommentButton from "./CommentButton";
import VideoTag from "../Others/OLDVideoTag";
import VideoPlayer from "./VideoPlayer";
import LoadingComponent from "../../Utils/LoadingComponent";
import DynamicVideoPlayer from "./DynamicVideoPlayer";
import VideoModal from "../Others/Videomodal";
import CardProfile from "./CardProfile";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson, faMedal, faContactCard, faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import DescriptionPost from "./DescriptionPost";
import TopPost from "./TopPost";
import { create } from "query/jquery";
import EpicPost from "./EpicPost";


export default function PostBar({ item }){
    const token = localStorage.getItem('userId');
    const {data, setData} = useContext(VideoContext);
    const [activePostId, setActivePostId] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [showDeletePost, setShowDeletePost] = useState(false)
    const [likeCount, setLikeCount] = useState(0);
    const { apiData } = useContext(ApiContext);
    const optionsRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentVideoSrc, setCurrentVideoSrc] = useState("");
   
    console.log("This is video DATA from POSTBAR:", data)

    // Handle the tree dot for more options
    const handleEdOptsOpen = (postId, event) => {
        event.stopPropagation();
        setActivePostId(activePostId === postId ? null : postId);
      };
    
      const handleOpenModal = (videoSrc) => {
        setCurrentVideoSrc(videoSrc);
        setIsModalOpen(true);
    };

      const handleClickOutside = (event) => {
        if (optionsRef.current && !optionsRef.current.contains(event.target)) {
          setActivePostId(null);
        }
      };

      useEffect(() => {
        window.addEventListener("click", handleClickOutside);
        return () => {
          window.removeEventListener("click", handleClickOutside);
        };
      }, []);
    //  ================== Edit Options Function =================
       /* $(".ed-opts-open").on("click", function(){
            $(this).next(".ed-options").toggleClass("active");
            return false;
        });*/
    // canDelete post function
    function canDelete(post){
        return post.uploaded_by===apiData[0].id
    }

    // make a optomistics delete
    async function handleDeletePost(postId) {
        // Display a confirmation dialog to the user
        if (!window.confirm("Are you sure you want to delete this post?")) {
            return;
        }
        // Make a copy of the current post data
        const currentData = [...data];
        // Find the index of the post to be deleted
        const postIndex = currentData.findIndex(post => post.id === postId);
        // Remove the post from the copy of the post data
        currentData.splice(postIndex, 1);
        // Update the state with the modified post data
        setData(currentData);
    
        try {
            // Send the DELETE request to the server
            const response = await fetch(`https://backproject.meanc.net/meanc/video/${postId}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            });
            if (!response.ok) {
                throw new Error('Failed to delete post');
            }
        } catch (error) {
            console.log(error);
            // If there is an error, revert back to the original post data
            setData(data);
        }
    }

    return (
        <>
        {!data || data.length === 0 ? (
          <LoadingComponent />
        ) : data.map(item=>(
            <div key={item.id} className="post-bar">
                <TopPost 
                    user_id={item.uploaded_by_id} 
                    created={item.created} 
                    username = {item.username}
                    photo = {item.photo}
                    country = {item.country}
                 />
                 
                <CardProfile 
                    category={item.category} 
                    height = {item.height}
                    weight = {item.weight}
                    school = {item.school}
                    play_position = {item.play_position}
                    hometown = {item.hometown}
                    achievements = {item.achievements}
                    experience_level = {item.experience_level}
                    sports = {item.sports}
                    team_name = {item.team_name}
                    firstname = {item.first_name} 
                    lastname = {item.last_name} 
                    src={item.processed_video_url} />
                
                <div className="job-status-bar">
                  {/*<a><ShareButton postUrl={item.video_file} ShareButton={ShareButton} /></a>*/}
                </div>
                <div>
                    <LikeComButton postId={item.id} />
                    <CommentButton postId={item.id} />
                </div>
            </div>
        ))}
        </>
    )
}