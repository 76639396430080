// ApiRoutes.js
import React, { useContext } from "react";
import { BrowserRouter as Router,Routes, Route, Outlet, Navigate} from 'react-router-dom';
import AboutHome from "../Components/AboutPage/AboutHome";
import Appsub from "../Containers/Appsub";
import Content from "../Components/Others/Contents";
import EmailConfirmation from "../Components/SignComponents/EmailConfirmation";
import HomeUserProfileDetail from "../Components/Profiles/HomeUserProfileDetail";
import Login from "../Components/Others/Login";
import NotFound from "../Components/PageLayout/NotFound";
import Sign from "../Components/SignComponents/Sign";
import SignUpBuffer from "../Components/SignComponents/SignUpBuffer";
import UploadVideo from "../Components/Others/Uploadvideo";
import CommunityGuidelines from "../ConformitiesPolicies/CommunityGuide";
import CookiesPolicy from "../ConformitiesPolicies/CookiesPolicies";
import CopyrightPolicy from "../ConformitiesPolicies/Copyright";
import UserDataDeletion from "../ConformitiesPolicies/UserDataDelete";
import TermsAndConditions from "../ConformitiesPolicies/ThemConditions";
import PrivacyPolicy from "../ConformitiesPolicies/Privacy";
import Recruiter from "../Containers/Recruiter";
import Talents from "../Containers/Talents";
import FeedbackHome from "../Containers/FeedbackHome";
import ChatHome from "../Components/Chats/ChatHome";
import HomeReferal from "../Components/Referals/HomeReferal";
import HomeUserProfileView from "../Components/Profiles/HomeUserProfileView";
import CompleteProfile from "../Utils/CompleteProfile";
import ReferralReport from "../micromanagement/ReferralReport";
import ForgotPassword from "../Components/SignComponents/ForgotPassword";
import Logout from "../Components/SignComponents/Logout";
// this is for test
import SimpleLogin from "../Components/Others/SimpleLogin";
import ProtectedPage from "../Components/Others/ProtectedPage";
import ApiContext from "../Components/globalStore/ApiContext";


const ProtecedRoutes = () =>{
    const user = useContext(ApiContext).apiData[0];
    return user ? <Outlet /> : <Navigate to="/login" />
    
}


export default function ApiRoutes(){

    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/"  element={<Navigate to="/home" replace />} />
                    <Route path="/login" element={<Sign />}/>
                    <Route path='/simplelogin' element={<SimpleLogin />}/>
                    <Route path="/forgot-password" element={<ForgotPassword/>} />
                    <Route path="/About" element={<AboutHome/>} />
                    <Route path="/Privacy" element={<PrivacyPolicy/>} />
                    <Route path="/Terms" element={<TermsAndConditions/>} />
                    <Route path="/Cookies" element={<CookiesPolicy/>} />
                    <Route path="/Copyright" element={<CopyrightPolicy/>} />
                    <Route path="/Community" element={<CommunityGuidelines/>} />
                    <Route path="/UserdataDelete" element={<UserDataDeletion/>} />
                    <Route element={<ProtecedRoutes />}>
                        <Route path="/logout" element={<Logout/>} />
                        <Route path='/home' element={<Appsub />}/>
                        <Route path='/protected' element={<ProtectedPage />}/>
                        <Route path="/signup-buffer" element={<SignUpBuffer />} />
                        <Route path="/confirm-email/:confirmation_code" element={<EmailConfirmation/>} />
                        <Route path="/content" element={<Content/>} />
                        <Route path="/complete-profile" element={<CompleteProfile />} />
                        <Route path="/recruiters" element={<Recruiter/>} />
                        <Route path="/talents" element={<Talents/>} />
                        <Route path="/upload" element={<UploadVideo/>} />
                        <Route path="/messenger" element={<ChatHome />} />
                        <Route path="/messenger/:userId" element={<ChatHome />} />
                        <Route path='/conversations' element={<FeedbackHome />} />
                        <Route path='/profile' element={<HomeUserProfileDetail/>} />
                        <Route path="/profiles/:userId/" element={<HomeUserProfileView />} />
                        <Route path='/referrals' element={<HomeReferal/>} />
                        <Route path='/moussa' element={<ReferralReport/>} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </div>
    )
}