import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { getData } from 'country-list';

export default function SignupUser({ referrerId }) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [againPassword, setAgainPassword] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [country, setCountry] = useState(null);
    const [errorRegister, setErrorRegister] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showModal, setShowModal] = useState(false);

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
            age--;
        }
        return age;
    };

    const handleRegister = (e) => {
        e.preventDefault();
        setIsLoading(true);

        // if (!country) {
        //     setErrorRegister(true);
        //     setErrorMessage(t('select_country'));
        //     setIsLoading(false);
        //     return;
        // }

        const item = { email, password, date_of_birth: dateOfBirth, country: country.value }; // Include date of birth and country in the data
        console.log(" here the data email:", email)
        console.log(" here the data password:", password)
        console.log(" here the data date_of_birth:", dateOfBirth)
        console.log(" here the data country:", country.label)
        let url = "https://backproject.meanc.net/meanc/users/";

        if (referrerId) {
            url = `https://backproject.meanc.net/meanc/users/register/${referrerId}/`;
        }

        if (password !== againPassword) {
            setErrorRegister(true);
            setErrorMessage(t('passwords_do_not_match'));
            setPassword('');
            setAgainPassword('');
            setIsLoading(false);
        } else if (calculateAge(dateOfBirth) < 13) {
            setErrorRegister(true);
            setErrorMessage(t('underage_error'));
            setIsLoading(false);
        } else {
            fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(item)
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(errorData => {
                            throw new Error(errorData.error || t('email_exists'));
                        });
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.message) {
                        window.location.href = '/signup-buffer';
                    } else {
                        setErrorRegister(true);
                        setErrorMessage(t('registration_error'));
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    console.log("Error:", error);
                    setErrorRegister(true);
                    setErrorMessage(error.message || t('registration_error'));
                    setIsLoading(false);
                });
        }
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    // Fetch country data
    useEffect(() => {
        const countries = getData().map((country) => ({
            value: country.code,
            label: country.name
        }));
        setCountryList(countries);
    }, []);

    const [countryList, setCountryList] = useState([]);

    return (
        <div className="dff-tab current" id="tab-3">
            <form onSubmit={handleRegister}>
                <div className="row">
                    <div className="col-lg-12 no-pdd">
                        <div className="sn-field">
                            <input
                                type="email"
                                value={email}
                                placeholder={t('enter_email')}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <i className="la la-user"></i>
                        </div>
                    </div>
                    {errorRegister && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
                    <div className="col-lg-12 no-pdd">
                        <div className="sn-field">
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={t('enter_password')}
                                required
                            />
                            <i className="la la-lock"></i>
                        </div>
                    </div>
                    <div className="col-lg-12 no-pdd">
                        <div className="sn-field">
                            <input
                                type="password"
                                value={againPassword}
                                onChange={(e) => setAgainPassword(e.target.value)}
                                placeholder={t('enter_password_again')}
                                required
                            />
                            <i className="la la-lock"></i>
                        </div>
                    </div>
                    <div className="col-lg-12 no-pdd">
                        <div className="sn-field">
                            <label style={{ marginLeft: '10px', marginBottom: '5px', marginTop: '0px' }}>
                                {t('enter_birth_date')}
                                <FontAwesomeIcon
                                    icon={faQuestionCircle}
                                    onClick={handleShowModal}
                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                />
                            </label>
                            <input
                                type="date"
                                value={dateOfBirth}
                                onChange={(e) => setDateOfBirth(e.target.value)}
                                required
                            />
                            <i className="la la-calendar"></i>
                        </div>
                    </div>
                    <div className="col-lg-12 no-pdd">
                        <div className="sn-field">
                            <label style={{ marginLeft: '10px', marginBottom: '5px', marginTop: '0px' }}>
                                {t('select_country')}
                            </label>
                            <Select
                                options={countryList}
                                value={country}
                                onChange={setCountry}
                                placeholder={t('select_country')}
                                required
                                styles={{width:'100%',
                                        padding: '0 15px 0 40px',
                                        color: 'black',
                                        borderRadius: '25px',
                                        fontSize: '14px',
                                        backgroundColor:'red',
                                        border:'2px solid #e5e5e5'
                                    }}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 no-pdd">
                        <p>{t('agree_terms_conditions')}
                            <Link style={{ color: 'green', cursor: 'pointer' }} to="/Terms">{t('termsAndConditions')}</Link>
                        </p>
                    </div>
                    <div className="col-lg-12 no-pdd" style={{ marginTop: '-20px', marginBottom: "10px" }}>
                        {isLoading ? <div className="lds-hourglassMin"></div> :
                            <button
                                type="submit"
                                value="submit"
                                disabled={isLoading}
                            >
                                <i>{t('sign_up')}</i>
                            </button>
                        }
                    </div>
                </div>
            </form>

            {/* Modal */}
            <Modal show={showModal} onHide={handleCloseModal} style={{ marginTop: '170px' }}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('modal_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('modal_body')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        {t('close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
