import React, { useState, useEffect } from 'react';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';
import { Spinner } from 'react-bootstrap';

const FacebookButton = () => {
  const [profile, setProfile] = useState(null);
  const [token, setToken] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);  // New state for loading

  useEffect(() => {
    if (profile) {
      console.log('Here my facebook profile:', profile);
      console.log('Here my facebook profile token:', profile.accessToken);
      const item = { token: profile.accessToken };

      setLoading(true);  // Start loading

      fetch('https://backproject.meanc.net/meanc/loginfacebook/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(item)
      })
      .then(response => response.json())
      .then(data => {
        console.log(data.token);
        if (!data.token) {
          setError(true);
        } else {
          setToken(data.token);
          localStorage.setItem("userId", data.token);
          localStorage.setItem("user_id", data.user_id);
          if (data.user_type) {
            window.location.href = "/home";
          } else {
            window.location.href = "/complete-profile";
          }
        }
      })
      .catch(error => {
        console.log('This is Error:', error);
        setError(true);
      })
      .finally(() => setLoading(false));  // Stop loading
    }
  }, [profile]);

  const buttonStyle = {
    height: '40px',
    borderRadius: '25px',
    padding: '15px 20px',
    fontWeight: 'bold',
    fontSize: '13px',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#3b5998',
    border: 'none',
    cursor: 'pointer',
    margin: '15px 1px',
    width: '250px',
  };

  return (
    <div>
      <LoginSocialFacebook
        appId='998825047770721'
        onResolve={(response) => {
          console.log(response);
          setProfile(response.data);
        }}
        onReject={(error) => {
          console.log(error);
        }}
      >
        <FacebookLoginButton style={buttonStyle} />
      </LoginSocialFacebook>
      {loading && <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>}
    </div>
  );
};

export default FacebookButton;
