import React, { useContext, useState, useEffect } from 'react';
import ApiContext from '../globalStore/ApiContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import formatNumber from '../../Utils/formatNumber';

export default function CommentLikeDislikeButton({ commentId }) {
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [disliked, setDisliked] = useState(false);
  const [dislikeCount, setDislikeCount] = useState(0);
  const { apiData } = useContext(ApiContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const likeResponse = await fetch(`https://backproject.meanc.net/meanc/comment_likes/filter_by_comment/?comment=${commentId}`);
        const dislikeResponse = await fetch(`https://backproject.meanc.net/meanc/comment_dislikes/filter_by_comment/?comment=${commentId}`);

        if (likeResponse.ok) {
          const likeData = await likeResponse.json();
          setLikeCount(likeData.length);
          const userLikes = likeData.filter(like => like.user === apiData[0].id);
          setLiked(userLikes.length > 0);
        }

        if (dislikeResponse.ok) {
          const dislikeData = await dislikeResponse.json();
          setDislikeCount(dislikeData.length);
          const userDislikes = dislikeData.filter(dislike => dislike.user === apiData[0].id);
          setDisliked(userDislikes.length > 0);
        }
      } catch (error) {
        console.error('Error fetching like/dislike data:', error);
      }
    };

    fetchData();
  }, [commentId, apiData]);

  const handleLike = async () => {
    if (!liked) {
      setLiked(true);
      setLikeCount(likeCount + 1);
      try {
        const response = await fetch('https://backproject.meanc.net/meanc/comment_likes/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: apiData[0].id,
            comment: commentId,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to like');
        }
      } catch (error) {
        console.error('Error liking:', error);
        setLiked(false);
        setLikeCount(likeCount - 1);
      }
    }
  };

  const handleDislike = async () => {
    if (!disliked) {
      setDisliked(true);
      setDislikeCount(dislikeCount + 1);
      try {
        const response = await fetch('https://backproject.meanc.net/meanc/comment_dislikes/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: apiData[0].id,
            comment: commentId,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to dislike');
        }
      } catch (error) {
        console.error('Error disliking:', error);
        setDisliked(false);
        setDislikeCount(dislikeCount - 1);
      }
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span onClick={handleLike} style={{ cursor: 'pointer', color: liked ? 'navy' : 'black' }}>
        <FontAwesomeIcon icon={faThumbsUp} />
      </span>
      <span style={{ marginLeft: '5px' }}>{formatNumber(likeCount)}</span> {/* Use formatNumber here */}
      <span onClick={handleDislike} style={{ cursor: 'pointer', color: disliked ? 'navy' : 'black', marginLeft: '10px' }}>
        <FontAwesomeIcon icon={faThumbsDown} />
      </span>
      <span style={{ marginLeft: '5px' }}>{formatNumber(dislikeCount)}</span> {/* Use formatNumber here */}
    </div>
  );
}
