import React, { useState, useEffect } from 'react';

function UploadVideo() {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [user, setUser] = useState('');
  const [description, setDescription] = useState('');
  const [videoUrl, setVideoUrl] = useState('');

  // Upload the video when the form is submitted
  const handleSubmit = (event) => {
    event.preventDefault();
    setUser(100);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('uploaded_by', user);

    fetch('https://backproject.meanc.net/meanc/video/', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => setVideoUrl(data.url))
      .catch(error => console.error(error));
  };

  // Update the video URL when the component mounts
  useEffect(() => {
    fetch('https://backproject.meanc.net/meanc/video/')
      .then(response => response.json())
      .then(data => setVideoUrl(data.url))
      .catch(error => console.error(error));
  }, []);

  // Render the component
  return (
    <div className="container">
      <h1>Upload Video</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Title:
          <input type="text" 
          value={title} 
          onChange={event => setTitle(event.target.value)} 
          required />
        </label>
        <br />
        <label>
          Description:
          <input type="text"
          value={description} 
          onChange={event => setDescription(event.target.value)}
          required />
        </label>
        <br />
        <label>
          Choose a video file:
          <input type="file" 
            accept="video/*" 
            onChange={event => setFile(event.target.files[0])}
            required />
        </label>
        <button type="submit" className="btn">Upload</button>
      </form>
      {videoUrl && <video src={videoUrl} controls />}
    </div>
  );
}

export default UploadVideo;
