import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import './Chat.css';
import ApiContext from '../globalStore/ApiContext';
import { useTranslation } from 'react-i18next';
import UserList from './UserList';
import ChatWindow from './ChatWindow';

export default function ChatComponent() {
    const { apiData } = useContext(ApiContext);
    const location = useLocation();
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [users, setUsers] = useState([]);
    const [userMap, setUserMap] = useState(new Map());
    const [loading, setLoading] = useState(true);
    const [loadingConversation, setLoadingConversation] = useState(true);
    const [showChat, setShowChat] = useState(false);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState(new Map());
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [isWsConnected, setIsWsConnected] = useState(false);
    const ws = useRef(null);
    const messagesEndRef = useRef(null);
    const { t } = useTranslation();

    const currentId = localStorage.getItem('currentId');
    console.log("This is the currentId", currentId);
    console.log("this is the selected User", selectedUserId);

    useEffect(() => {
        // Check for user parameter in URL
        const urlParams = new URLSearchParams(location.search);
        if (urlParams.has('user')) {
            const userId = urlParams.get('user');
            console.log("User ID detected:", userId);
            setSelectedUserId(userId); // Store user ID in state
            setShowChat(true);
        } else {
            console.log("No user ID detected. Regular URL.");
        }
    }, [location]);

    const handleUserClick = (userId) => {
        if (ws.current) {
            console.log("Closing current WebSocket connection");
            ws.current.close();  // Close the existing connection
        }

        setSelectedUserId(userId);
        setShowChat(true);  // This will trigger the useEffect hook

        // Mark messages as read
        setUnreadMessages(prev => {
            const newMap = new Map(prev);
            newMap.delete(userId);
            return newMap;
        });
    };

    useEffect(() => {
        if (showChat && selectedUserId) {
            console.log("Opening new WebSocket connection for this user:", selectedUserId);
            
            ws.current = new WebSocket(`wss://messenger.meanc.net/ws/chat/${selectedUserId}/`);

            ws.current.onopen = () => {
                console.log("Connected to the server. Conversation between:", selectedUserId, "and", apiData[0].id);
                setIsWsConnected(true);
            };

            ws.current.onerror = (error) => {
                console.error("WebSocket connection error:", error);
            };

            ws.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log("Received message from server:", data);

                if (data.type === 'chat_message') {
                    // Update the message if it exists, otherwise add it
                    setMessages(prevMessages => prevMessages.map(msg =>
                        msg.tempId === data.tempId ? { ...msg, confirmed: true, timestamp: data.timestamp } : msg
                    ).concat(!prevMessages.some(msg => msg.tempId === data.tempId) ? [{
                        message: data.message,
                        sender_id: data.sender_id,
                        tempId: data.tempId,
                        confirmed: true,
                        timestamp: data.timestamp
                    }] : []));

                    // Mark as unread if the message is from another user
                    if (data.sender_id !== apiData[0].id && data.recipient_id === apiData[0].id) {
                        setUnreadMessages(prev => {
                            const newMap = new Map(prev);
                            if (!newMap.has(data.sender_id)) {
                                newMap.set(data.sender_id, true);
                            }
                            return newMap;
                        });
                    }
                } else if (data.type === 'all_messages') {
                    setMessages(data.messages.map(msg => ({ ...msg, confirmed: true, timestamp: msg.timestamp })));
                    setLoadingMessages(false);
                }
            };

            ws.current.onclose = () => {
                setIsWsConnected(false);
            };

        }

        // Clean up the WebSocket when component unmounts or when chat closes or user changes
        return () => {
            if (ws.current) {
                console.log("Closing WebSocket connection");
                ws.current.close();
            }
        };
    }, [selectedUserId, showChat]);  // Dependencies that trigger the WebSocket setup

    useEffect(() => {
        if (isWsConnected && selectedUserId) {
            fetchMessages(selectedUserId);
        }
    }, [isWsConnected]);

    useEffect(() => {
        if (!loadingMessages) {
            scrollToBottom();
        }
    }, [messages, loadingMessages]);

    const fetchMessages = (userId) => {  // Accept userId as a parameter
        console.log("fetching data from the conversation.....", userId, 'and', apiData[0].id)
        if (!userId) {
            console.error("No selected user. Cannot fetch messages.");
            return;
        }

        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            console.log("The connection is open and ready");
            ws.current.send(JSON.stringify({
                type: 'fetch_messages',
                sender_id: apiData[0].id,
                recipient_id: userId
            }));
            setLoadingMessages(true);
        }
    };

    const sendMessage = () => {
        if (!selectedUserId) {
            console.error("No selected user. Cannot send message.");
            return;
        }

        if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
            console.error("WebSocket connection not open. Cannot send message.");
            return;
        }

        if (!inputValue.trim()) {
            console.error("Message input is empty. Cannot send empty message.");
            return;
        }

        const tempId = new Date().getTime(); // Unique ID for optimistic updates
        const newMessage = {
            sender_id: apiData[0].id,
            message: inputValue,
            tempId,
            confirmed: false, // Initially false
            timestamp: new Date().toISOString() // Add timestamp here
        };

        console.log("Sending message:", newMessage);
        setMessages(prev => [...prev, newMessage]); // Add to state immediately

        // Send the message to the server
        const messageData = {
            message: inputValue,
            sender_id: apiData[0].id,
            recipient_id: selectedUserId,
            tempId // Include tempId to track the message
        };

        if (ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify(messageData));
            setInputValue(""); // Clear input after sending
        } else {
            console.error("WebSocket connection not open. Cannot send message.");
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            sendMessage();
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://messenger.meanc.net/chat-api/usersprofile/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsers(data);
                const userMap = new Map(data.map(user => [user.id, user]));
                setUserMap(userMap);
                console.log("data from chatComponent 1:", data);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch users:", error);
                setLoading(false);
            }
        };

        const fetchConversations = async () => {
            const token = localStorage.getItem('userId');
            setLoadingConversation(true);
            try {
                const response = await fetch('https://messenger.meanc.net/chat-api/conversations/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setConversations(data);
                console.log("Data from chatComponent, Conversations:", conversations);
                setLoadingConversation(false);
            } catch (error) {
                console.error("Failed to fetch conversations:", error);
                setLoadingConversation(false);
            }
        };

        fetchUsers();
        fetchConversations();

        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    }, []);

    const getUserPhoto = (userId) => {
        const user = userMap.get(userId);
        return user && user.photo ? user.photo : `https://robohash.org/${userId}`;
    };

    const getUsername = (userId) => {
        const user = userMap.get(userId);
        return user ? user.username : "Unknown User";
    };

    const addEmoji = (emoji) => {
        setInputValue(prevInputValue => prevInputValue + emoji.native);
        setShowEmojiPicker(false);
    };

    if (!apiData || apiData.length === 0) {
        return <div className="process-comm">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
               </div>;
    }

    return (
        <>
        <section className="companies-info">
            <div className="container">
                <h3 className="fixed-header">{t('letsHaveAChat')}</h3>
                <div className="row">
                    {!showChat && (
                        <UserList
                            users={users}
                            conversations={conversations}
                            unreadMessages={unreadMessages}
                            apiData={apiData}
                            searchTerm={searchTerm}
                            handleUserClick={handleUserClick}
                            setSearchTerm={setSearchTerm}
                        />
                    )}
                    <ChatWindow
                        showChat={showChat}
                        messages={messages}
                        loadingMessages={loadingMessages}
                        messagesEndRef={messagesEndRef}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        handleKeyDown={handleKeyDown}
                        sendMessage={sendMessage}
                        showEmojiPicker={showEmojiPicker}
                        setShowEmojiPicker={setShowEmojiPicker}
                        addEmoji={addEmoji}
                        setShowChat={setShowChat}
                        getUserPhoto={getUserPhoto}
                        getUsername={getUsername}
                        apiData={apiData}
                        selectedUserId={selectedUserId}
                    />
                </div>
            </div>
        </section>
        </>
    );
}
