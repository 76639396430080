import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, ListGroup } from 'react-bootstrap';
import ApiContext from "../globalStore/ApiContext";
import Header2 from "../PageLayout/Header2";
import Footer from "../PageLayout/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { maskEmail } from "../../Utils/maskEmail";
import VideoModal from "../Others/Videomodal";

export default function HomeUserProfileView() {
  const { userId } = useParams(); // Get userId from the URL
  const { apiData } = useContext(ApiContext);
  const [userData, setUserData] = useState(null);
  const [extraData, setExtraData] = useState({});
  const [videos, setVideos] = useState([]); // State to store videos here
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [selectedVideo, setSelectedVideo] = useState(""); // State to store the selected video URLd d

  useEffect(() => {
    fetch(`https://backproject.meanc.net/meanc/users/${userId}/`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then(response => response.json())
      .then(data => {
        setUserData(data);
        if (data.user_type === 'player') {
          fetch(`https://backproject.meanc.net/meanc/players/get-by-user/${userId}/`, {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
            }
          })
            .then(response => response.json())
            .then(extraData => setExtraData(extraData))
            .catch(error => console.log("Error fetching player extra data:", error));

          // Fetch videos if user_type is 'player'
          fetch(`https://backproject.meanc.net/meanc/video/by-user/${userId}/`, {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
            }
          })
            .then(response => response.json())
            .then(videos => setVideos(videos))
            .catch(error => console.log("Error fetching player videos:", error));
        } else if (data.user_type === 'recruiter') {
          fetch(`https://backproject.meanc.net/meanc/recruiters/get-by-user/${userId}/`, {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
            }
          })
            .then(response => response.json())
            .then(extraData => setExtraData(extraData))
            .catch(error => console.log("Error fetching recruiter extra data:", error));
        } else {
          fetch(`https://backproject.meanc.net/meanc/simple-users/get-by-user/${userId}/`, {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
            }
          })
            .then(response => response.json())
            .then(extraData => setExtraData(extraData))
            .catch(error => console.log("Error fetching simple user extra data:", error));
        }
      })
      .catch(error => {
        console.log("Error fetching user data:", error);
      });
  }, [userId]);

  if (!userData) {
    return (
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

  const renderPlayerInfo = () => (
    <div className="col-lg-6">
      <div className="main-ws-sec">
        <div className="user-tab-sec">
          <h3>{userData.first_name || "N/A"} {userData.last_name || "N/A"}</h3>
          <div className="star-descp">
                <span>
                  Play position - {' '}
                  {extraData.play_position ? (
                      <strong style={{ color: 'black', fontWeight: 'bold' }}>{extraData.play_position}</strong>
                  ) : (
                      <strong><div className="loader"></div></strong>
                  )}
                </span>
          </div>
          <div className="tab-feed">
            <Row>
              <Col xs={6}>
                <ListGroup variant="flush">
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Height</div>
                    <div className="info-data">{extraData.height || 'N/A'}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Weight</div>
                    <div className="info-data">{extraData.weight || 'N/A'}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Hometown</div>
                    <div className="info-data">{extraData.hometown || 'N/A'}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Follow</div>
                    <div className="info-data">{userData.follower_count || '0'}</div>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col xs={6}>
                <ListGroup variant="flush">
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Club</div>
                    <div className="info-data">{extraData.team_name || 'N/A'}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className="info-item">
                    <div className="info-label">GPA</div>
                    <div className="info-data">{extraData.gpa || 'N/A'}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Play position</div>
                    <div className="info-data">{extraData.play_position || 'N/A'}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className="info-label">
                  <div className="info-label">School</div>
                    <div className="info-data">{extraData.school || 'N/A'}</div>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
  const renderRecruiterInfo = () => (
    <div className="col-lg-6">
      <div className="main-ws-sec">
        <div className="user-tab-sec">
          <h3>{userData.first_name || "N/A"} {userData.last_name || "N/A"}</h3>
          <div className="star-descp">
            <span>Recruiter</span>
          </div>
          <div className="tab-feed">
            <Row>
              <Col xs={6}>
                <ListGroup variant="flush">
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Company</div>
                    <div className="info-data">{extraData.company || 'N/A'}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Contact Number</div>
                    <div className="info-data">{extraData.contact_number || 'N/A'}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Email</div>
                    <div className="info-data">{maskEmail(extraData.email) || 'N/A'}</div>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col xs={6}>
                <ListGroup variant="flush">
                  <ListGroup.Item className="info-item">
                    <div className="info-label">First Name</div>
                    <div className="info-data">{extraData.first_name || 'N/A'}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Last Name</div>
                    <div className="info-data">{extraData.last_name || 'N/A'}</div>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );

  const renderSimpleUserInfo = () => (
    <div className="col-lg-6">
      <div className="main-ws-sec">
        <div className="user-tab-sec">
          <h3>{userData.first_name || "N/A"} {userData.last_name || "N/A"}</h3>
          <div className="star-descp">
            <span>Simple User</span>
          </div>
          <div className="tab-feed">
            <Row>
              <Col xs={6}>
                <ListGroup variant="flush">
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Hobbies</div>
                    <div className="info-data">{extraData.hobbies || 'N/A'}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Interests</div>
                    <div className="info-data">{extraData.interests || 'N/A'}</div>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col xs={6}>
                <ListGroup variant="flush">
                  <ListGroup.Item className="info-item">
                    <div className="info-label">Occupation</div>
                    <div className="info-data">{extraData.occupation || 'N/A'}</div>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );

  const handleOpenModal = (videoSrc) => {
    setSelectedVideo(videoSrc);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedVideo("");
  };

  const renderVideos = () => (
    <div className="pf-gallery">
      <ul>
        {videos.length > 0 ? (
          videos.map((video, index) => (
            <li key={index} style={{ backgroundColor: 'orange', padding: '20px', marginRight: '10px', boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)' }}>
              <a  title="" onClick={() => handleOpenModal(video.processed_video_url)}>
                <span>
                  <FontAwesomeIcon icon={faPlayCircle} size="2x" />
                </span>
              </a>
            </li>
          ))
        ) : (
          <li>No videos found</li>
        )}
      </ul>
    </div>
  );

  return (
    <div className="wrapper">
      <>
        <Header2 />
        <section
          className="cover-sec"
          style={{
            position: 'relative',
            width: '100%',
            height: '150px',
            backgroundImage: 'url("https://www.transparenttextures.com/patterns/clean-gray-paper.png")',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <div className="usr-pic" style={{ position: 'absolute', bottom: '-5px', left: '70px', borderRadius: '50%', overflow: 'hidden', border: '5px solid white' }}>
            {userData.photo ? (
              <img src={`${userData.photo}`} alt={`User ${userId}`} style={{ cursor: 'pointer', background: '#63b0e3', width: '100px', height: '100px' }} />
            ) : (
              <img src={`https://robohash.org/${userId}`} style={{ cursor: 'pointer', background: '#63b0e3', width: '100px', height: '100px' }} />
            )}
          </div>
        </section>
        <main>
          <div className="main-section" style={{ marginTop: '-35px' }}>
            <div className="container">
              <div className="main-section-data">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="main-left-sidebar">
                      <div className="user_profile">
                        <div className="user_pro_status">
                          <ul className="flw-hr">
                            <li><a href="#" title="" className="flww"><i className="la la-plus"></i> Follow</a></li>
                            <li><a href="#" title="" className="hre">UnFollow</a></li>
                          </ul>
                          <ul className="flw-status">
                            <li>
                              <span>Following</span>
                              <b>{userData.following_count || 0}</b>
                            </li>
                            <li>
                              <span>Followers</span>
                              <b>{userData.follower_count || 0}</b>
                            </li>
                          </ul>
                        </div>
                        <ul className="social_links">
                          <li>Username<a href="#" title="">{userData.username}</a></li>
                          <li>Email <a href="#" title=""> {maskEmail(userData.email)}</a></li>
                          <li>Account Type <a href="#" title=""> {userData.user_type}</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {userData.user_type === 'player' ? renderPlayerInfo() : userData.user_type === 'recruiter' ? renderRecruiterInfo() : renderSimpleUserInfo()}
                  <div className="col-lg-3">
                    <div className="right-sidebar">
                      <div className="widget widget-portfolio">
                        {userData?.user_type === 'player' ? (
                          <>
                            <div className="wd-heady">
                              <h3>Videos</h3>
                              <img src="images/photo-icon.png" alt="" />
                            </div>
                            {renderVideos()}
                          </>
                        ) : (
                          <div className="wd-heady">
                            <h3>Make A Story</h3>
                            <img src="images/photo-icon.png" alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </>
      <VideoModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        videoSrc={selectedVideo}
      />
    </div>
  );
}
