import React, { useState } from "react";
import Header3 from "../Components/PageLayout/header3";
import Footer from "../Components/PageLayout/Footer";

const CompleteProfile = () => {
  const [userType, setUserType] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = localStorage.getItem("userId"); // this is the token
    const user_id = localStorage.getItem("user_id"); // this is the real user id

    console.log("Submitting user type update:", { user_id, userType });

    fetch(`https://backproject.meanc.net/meanc/users/${user_id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`,
      },
      body: JSON.stringify({ user_type: userType }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Response data:", data);
        if (data.user_type === userType) {
          window.location.href = "/home";
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
        setError(true);
      });
  };

  return (
    <>
      <Header3 />
      <div className='complete-profile'>
        <h1>Select Your User Type</h1>
        <form onSubmit={handleSubmit}>
          <div className='card-container'>
            <div 
              className={`card ${userType === 'recruiter' ? 'selected' : ''}`} 
              onClick={() => setUserType('recruiter')}
            >
              <img src="/images/recruiter.png" alt="Recruiter" className="card-image"/>
              <h2>Recruiter</h2>
              <p>Find and recruit top sports talent. Connect with players and manage your scouting network.</p>
              {userType === 'recruiter' && (
                <button type="submit" className='submit-button'>Click here</button>
              )}
            </div>
            <div 
              className={`card ${userType === 'player' ? 'selected' : ''}`} 
              onClick={() => setUserType('player')}
            >
              <img src="/images/player.png" alt="Player" className="card-image"/>
              <h2>Player</h2>
              <p>Showcase your skills, get noticed by recruiters, and take your sports career to the next level.</p>
              {userType === 'player' && (
                <button type="submit" className='submit-button'>Click here</button>
              )}
            </div>
            <div 
              className={`card ${userType === 'simple' ? 'selected' : ''}`} 
              onClick={() => setUserType('simple')}
            >
              <img src="/images/simple_user_selected.png" alt="Simple User" className="card-image"/>
              <h2>Simple User</h2>
              <p>Follow your favorite players and recruiters, and stay updated with the latest sports news and events.</p>
              {userType === 'simple' && (
                <button type="submit" className='submit-button'>Click here</button>
              )}
            </div>
          </div>
          {error && <p className='error-message'>Error completing profile. Please try again.</p>}
        </form>
      </div>
      <Footer />
    </>
  );
};

export default CompleteProfile;
